.top-bar {
  display: flex;
  justify-content: space-between;
  margin: 10px 10px 0 10px;
}

.form-bar {
  display: flex;

}

.form-label-bar {
  display: flex;
  margin: 0px 20px;
}

.search-bar {
  flex-grow: 10;
  margin: 0px 10px;
  border: 1px solid black;
  width: 100%;
}

.search-bar-empty {
  flex-grow: 10;
  width: 95%;
}

.save-btn {
  margin: 0px 5px;

  flex-grow: 1;
}

.delayed-btn {
   margin: 0 5px;
   flex-grow: 1;
}
.discard-btn {
  margin: 0px 5px;

  flex-grow: 1;
}

.product-container {
  border: 1px solid black;
  box-sizing: border-box;
  display: flex;
  margin: 10px;
  position: relative;  
}


.delete-button {
  display: block;
  padding: 0px 5px;
  height: 25px;
  margin: 5px;
  border: 1px solid red;
  color: red;
  background-color: transparent;
  cursor: pointer;
  margin-left: 10px;
}

.delete-button:hover {
  background-color: red;
  color: white;
}

.clone-button {
  display: block;
  padding: 0px 5px;
  height: 25px;
  margin: 5px;
  border: 1px solid green;
  color: green;
  background-color: transparent;
  cursor: pointer;
  margin-left: 10px;
}

.clone-button:hover {
  background-color: green;
  color: white;
}

.form-bar-select {
  margin: 0px 5px;
  width: 200px;
}


.product-info {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.product-info-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}


.product-info-item {
  width: 24.5%;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.product-info-text-product-name{
  width: 200px;
}

.product-notes {
  grid-area: 2/4/4/4;
  padding: 10px;
}

.payment-date {
  grid-area: 3/4/3/4;
  padding: 5px;
  font-size: 12px;
}

.product-info-label {
  opacity: 70%;
}

.product-name {
  width: 200px;
  padding: 10px;

}

.product-info-btns {
  display: flex;
  width: 24.5%;
  flex-direction: row;
  justify-content: space-between;
}

.discounted-tag {
  display: flex;
  flex-direction: column;
  padding: 10px;
  color: rgb(177, 122, 3);
}

.first-product-tag {
  display: flex;
  flex-direction: column;
  padding: 10px;
  color: rgb(3, 70, 177);
}
.extra-margin {
  margin-left: 115px;
}

.customer-name {
  width: 150px;
  padding: 10px;
}

.vendor-name {
  width: 150px;
  padding: 10px;
}

.product-image {
  height: 100%;
  width: auto;
  object-fit: contain;
}

.product-image-container {
  width: 275px;
  overflow: hidden;
  min-width: 275px;
}

.product-price-text {
  text-align: left;
}

.product-supplier-link {
  margin-top: 10px;
  grid-area: 4/1/span 1/span 4;
  padding: 5px;
  display: inline-block;
  max-width: 800px; 
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.product-stock-code {
  grid-area: 5/1/span 1/span 4;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.changed {
  border: 1px solid Chartreuse;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

.overlay-warning {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  font-size: 2rem;
  font-weight: 800;
  z-index: 2;
}

.copy-icon {
  margin-left: 5px;
  cursor: pointer;
}

.form-label-bar-second-row {
  width: 100%;
  margin: 0 10px;
}

.form-bar-second-row {
  width: 100%;
  display: flex;
  margin: 0 10px;
}

.form-bar-select-second-row {
  width: 20%;
}