.search-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
}

.search-wrapper .search-input {
    width: 100%;
}

form .order-search-area {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: solid 1px black;
}

.order-search-area input {
    width: 100%;
    outline: none;
    border: none;
    height: 30px;
}

.order-search-area .search-icon {
    margin-left: 5px;
    font-size: 15px;
    color: #000;
}

.order-search-area .x-icon {
    margin-right: 5px;
    font-size: 15px;
    color: black;
    cursor: pointer;
}

form .order-search-results {
    position: absolute;
    background-color: rgb(255, 255, 255);
    width: 100%;
    border-bottom: 1px solid #000000;
    border-right: 1px solid #000000;
    border-left: 1px solid #000000;
    z-index: 100;
}
.order-search-results li {
    padding: 10px;
    border-bottom: 1px solid #eee;
    align-items: stretch;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.order-search-results li .name {
    margin-right: 10px;
}

.order-search-results li .type {
    margin-left: 10px;
}

.order-search-results li:hover {
    background-color: #f9f5f0ee;
}

.order-search-result {

    padding: 0;
  
}
.search-results .list-all {
    border: none;
    border-top: solid 1px #000000;
}

.hidden {
    overflow: hidden;
}