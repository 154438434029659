.add-exchange-main-container {
    padding: 1rem;
    display: flex;
    flex-direction: column;

    .add-exchange-header-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .add-exchange-header-button-container {
        display: flex;
        flex-direction: row;
        gap: 1rem;
    }

    .add-exchange-body-row-container {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
        gap: 10px;
        margin-top: 0.5rem
    }

    .add-exchange-add-product-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align: left;
        border: solid black 1px;
        border-radius: 0.3rem;
        padding: 1rem;
        gap: 2rem;
        
        .add-exchange-add-product-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

    }

    .inline-inputs-refund-margin {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
        gap: 10px;
        margin-top: -1rem;

        .custom-product-selector {
            .ant-select-selector {
                height: 100px !important;
            }
        }
    }

    .add-product-reasons{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .ant-form-item{
            margin-bottom: 5px !important;
        }
    }

    .add-exchange-added-products-main-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align: left;
        border: solid black 1px;
        border-radius: 0.3rem;
        padding: 1rem;
        gap: 1rem;
        margin-top: 2rem;

        .add-exchange-added-products {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 1rem;
            border: solid black 1px;
            border-radius: 0.2rem;
            padding: 1rem;

            .add-exchange-added-products-button {
                margin-left: auto;
            }
        }
    }
}