.list-users-container {
    padding: 0 10px;
  }
  .list-users-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .list-users-filter {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
  }
  
  .users-filter-form {
    display: grid;
    grid-template-columns: 1fr;
    margin-right: 25px;
  }
  
  .users-filter-form div {
    width: 110px;
  }
  
  .search {
    width: 50%
  }
  
  .list-users-table-head {
    background-color: rgb(194, 194, 194);
    display: grid;
    grid-template-columns: 25px 1fr 1fr 1fr 30px;
    gap: 10px;
    padding: 10px 0;
  }
  