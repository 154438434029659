.container {
    padding: 0 10px;
}

.customer-form {
    width: 100%;
}

.customer-form-top {
    text-align: start;
    width: 100%;
    border: 1px solid black;
    padding: 10px 10px 100px 10px;
    margin-bottom: 10px;
}

.customer {
    display: grid;
    grid-template-columns: 3fr 13fr 2fr;
    gap: 20px;
    border: 1px solid black;
    padding: 10px;
    margin-bottom: 10px;
}

.customer {
    text-align: start;
    align-items: center;
}

.box {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    margin: 0 -10px;
    padding: 10px;

}

.credit-info-container {
    padding: 20px;
    display: grid;
    grid-template-columns: 2.3fr 1fr;
    gap: 20px;
    margin-top: -30px;
}

.non-paid-orders{
    padding: 20px;
}

.credit-application-buttons {
    margin-top: 20px;
    display: flex;
    gap: 10px;
    justify-content: flex-start;
}

.credit-application-buttons > .approve {
    background-color: rgb(19, 174, 37);
    border-color: rgb(30, 255, 56);
    color: rgb(255, 255, 255);
}

.credit-application-buttons > .approve:hover {
    background-color: rgb(76, 169, 87);
}

.credit-application-buttons > .disapprove {
    background-color: rgb(255, 30, 30);
    border-color: rgb(255, 30, 30);
}

.credit-application-buttons > .disapprove:hover {
    background-color: rgb(255, 97, 97);;
}

.inside-box {
    border: 1px solid black;
    margin: 0 10px;
    padding: 10px;
}
 
.inline_inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.findex-status {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
}

.findex-warning {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
}

.findex-warning > span {
    color: red;
    font-size: 0.9rem;
}

.findex-buttons {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: flex-start;
}

.findex-status-label {
    font-weight: 600;
    margin: 0;
    margin-right: 10px;
    margin-top: -2px;
    font-size: 1rem;
}

.right {
    height: 80%;
}

.papers {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.paper {
    max-width: 20%;
}

.border {
    border: 1px solid rgb(149, 149, 149);
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 10px;
}

.paper-name {
    height: 50px;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 15px;
}

.payment-list {
    border-radius: 10px;
    border: 1px solid rgb(170, 170, 170);
    padding: 10px;
    overflow: scroll;
    height: 550px;
}

.payment {
    padding: 5px;
    border-bottom: 1px solid rgb(170, 170, 170);;
}

.label {
    margin: 20px 0 10px 0;
    font-weight: 600;
    font-size: 1.3rem;
    display: flex;
}

.left-aligned {
    justify-content: flex-start;
}

.inline_three_inputs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    width: 100%;
}

.inline_five_inputs {
    display: grid;
    grid-template-columns: 1fr 1fr 1.1fr 1fr 1fr;
    gap: 10px;
    width: 100%;
}

.inline_four_inputs {
    display: grid;
    grid-template-columns: 1fr 1fr 1.1fr 1fr;
    gap: 10px;
    width: 100%;
}

.details-container {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
.detail-info-row {
    display: flex;
    flex-direction: row;
    
  }

.details-item {
    margin: 5px;
    padding: 5px;
      
    flex: 0 0 200px;
}

.header-save {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}

.payment-date {
    width: 100%;
}

.tab-container {
    display: flex;
    flex-direction: row;
    padding: 10px;
    border-bottom: 1px solid rgb(215, 215, 215);
    margin-bottom: 20px;
}

.tab-container .tab {
    width: 150px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1rem;
    cursor: pointer;
    font-weight: 600;
}

.tab-container>.tab:hover {
    text-decoration: underline;
    background-color: rgb(223, 223, 223);
}

.header-buttons {
    margin: 0 30px 0 0 ;
}

.header-buttons > button {
    height: 40px;
    font-size: 1.1rem;
    width: 90px;
}

.tab-button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.selected {
    color: rgb(74, 117, 244);
}

.boutique-location {
    margin-top: -0.3rem;
}

.add-coupon-button-container{
    text-align: end;
    padding: 10px;
    margin-bottom: 10px;
}

.experiments-box {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
}

.experiments-box .experiment-container {
    font-size: 0.8rem;
    padding: 1rem 0;
    border-top: 0.5px solid grey;
}

.experiments-box .experiment-container .experiment-key {
    font-weight: 600;
}