.tag-products-container {
    display: flex;
    flex-direction: column;

    .tag-products-top-bar {
        padding: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid black;
        align-items: center;

        .tag-products-header {
            margin: 0;
        }

        .tag-products-top-btns {
            display: flex;
            flex-direction: row;
            gap: 1rem;
        }
    }

    .tag-products-filters {
        padding: 0.4rem 1rem;
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        
        .tag-products-search-bar {
            width: 40%;
        }

        .tag-products-filter {
            width: 20%;
        }

        .wider-tag-products-filter {
            width: 25%;
        }

    }

    .tag-products-bottom-labels {
        padding: 0 1rem;
        display: flex;
        flex-direction: row;
        gap: 0.5rem;

        .tag-products-search-bar {
            width: 40%;
        }

        .tag-products-filter {
            width: 15%;
        }

        .wider-tag-products-filter {
            width: 20%;
        }
    }

    .tag-products-filter-labels {
        display: flex;
        flex-direction: row;
        padding: 0 1rem;
        gap: 0.5rem;
        margin-top: 0.3rem;
        margin-bottom: -0.5rem;
        
        .tag-products-search-bar {
            width: 40%;
        }

        .tag-products-filter {
            width: 20%;
        }

        .wider-tag-products-filter {
            width: 25%;
        }
        
    }

    .tag-products-tags-filter {
        width: 30%;
        padding: 0 0.3rem 0 0;
    }

    .tag-products-items {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .tag-product-item {
            border: 1px solid grey;
            border-radius: 0.3rem;
            overflow: hidden;
            display: flex;
            flex-direction: row;

            &.changed-tag-product-item {
                box-shadow: 0 0 2px 2px orange;
            }

            .tag-product-item-image-container {
                width: 25%;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                border-right: 1px solid grey;
                position: relative;

                .tag-product-item-image-left-icon {
                    position: absolute;
                    top: 47%;
                    left: 0.7rem;
                    z-index: 500;
                    font-size: 1.5rem;
                    cursor: pointer;
                }

                .tag-product-item-image-right-icon {
                    position: absolute;
                    top: 47%;
                    right: 0.7rem;
                    z-index: 500;
                    font-size: 1.5rem;
                    cursor: pointer;
                }

                .tag-product-image-navigation-indicators {
                    position: absolute;
                    width: 100%;
                    bottom: 0.3rem;
                    display: flex;
                    flex-direction: row;
                    gap: 0.3rem;
                    z-index: 500;
                    padding: 0 0.3rem;

                    .tag-product-image-navigation-indicator {
                        width: 100%;
                        height: 0.3rem;
                        background-color: rgb(179, 179, 179);
                    }

                    .tag-product-image-navigation-active-indicator {
                        background-color: rgb(93, 93, 93);
                    }
                }
            }

            .tag-product-item-info {
                width: 75%;

                .tag-product-item-general-info {
                    padding: 0.5rem;
                    display: flex;
                    flex-direction: column;
                    border-bottom: 1px solid grey;

                    .tag-product-item-general-info-row {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        gap: 1rem;

                        .tag-product-item-general-info-row-element {
                            width: 24%;
                        }

                        .tag-product-item-general-info-row-name-element {
                            width: 50%;
                        }
                    }

                    .tag-product-type-item-general-info-row {
                        display: grid;
                        grid-template-columns: 3fr 1fr;
                        gap: 1rem;
                    }
                }

                .tag-product-added-tags {
                    border-bottom: 1px solid grey;
                    display: flex;
                    flex-direction: row;
                    gap: 1rem;
                    flex-wrap: wrap;
                    padding: 1rem;

                    .tag-product-added-tag {
                        padding: 0.2rem 0.4rem;
                        display: flex;
                        flex-direction: row;
                        gap: 0.5rem;
                        border: 1px solid rgb(220, 220, 220);
                        height: min-content;
                        align-items: center;

                        .tag-product-added-tag-delete {
                            color: red;
                        }

                        .tag-product-added-tag-type {
                            font-weight: 700;
                            color: rgb(14, 107, 24);
                        }
                    }
                }

                .tag-product-select-tags {
                    padding: 1rem;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    row-gap: 1rem;
                    column-gap: 0.5rem;
                    
                    .tag-product-select-tag-container {
                        width: 23%;
                        display: flex;
                        flex-direction: column;
                        
                        .error-display .ant-select-selector {
                            border-color: red !important;
                        }
                    }
                }
            }
        }
    }

    .tag-products-collapsable-component {
        width: 100%;
    }

}
