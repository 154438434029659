.add-lonca-invoice-main-container {
    padding: 0 10px;
}

.add-lonca-invoice-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin: 10px 1rem;
}

.add-lonca-invoice-button-group {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.add-lonca-invoice-input-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    padding: 4rem 1rem;
}

.add-lonca-invoice-products-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.add-lonca-invoice-form-item .ant-form-item-label > label {
    font-weight: bold;
    font-size: 14px;
}

.add-lonca-invoice-form-item .ant-select {
    width: 400px;
}

.add-lonca-invoice-form-item .ant-input {
    width: 400px;
}

.add-lonca-invoice-form-item .ant-picker {
    width: 400px;
}