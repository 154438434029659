.layout-nav {
  height: 45px;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;
  border-bottom: 1px solid black;
  position: fixed;
  width: 100vw;
  top: 0;
  background-color: white;
  z-index: 1000;

  .test-data-label {
    position: fixed;
    left: 50%;
    top: 1rem;
    font-size: 15px;
    color: white;
    background-color: #40a9ff;
    padding: 0.3rem 1rem 0rem 1rem;
  }

  .logo{
    font-family: 'Times New Roman', Times, serif;
  }
  

}

.test-mode {
  border-bottom: 3px solid #40a9ff;
}
.buttons-item {
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: row;
  column-gap: 10px;
}

.main-layout {
  display: grid;
  column-gap: 60px;
  row-gap: 10px;
  grid-template-columns: 200px 1fr;
  grid-template-areas: 'aside section';
  padding-bottom: 50px;
  margin-top: 45px;
  z-index: -4;
  overflow: hidden;
}

.layout-aside {
  padding-bottom: 50px;
  grid-area: aside;
  border-right: 1px solid black;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: fixed;
  overflow: scroll;
  height: 100%;
}
.layout-aside a {
  display: inline-block;
  width: 100%;
}
.layout-aside button {
  width: 100%;
  text-align: start;
}

.layout-section {
  grid-area: section;
  padding-bottom: 15px;
  border-right: 1px solid black;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  text-align: center;
  overflow: scroll;
  height: calc(100vh - 45px);
}

.none {
  display: none;
}