.csv-discounted-products-container {
    display: flex;
    flex-direction: column;

    .csv-discounted-products-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 1rem;
        border-bottom: 1px solid black;

        .csv-discounted-products-header-title-spin {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            align-items: center;

            h2 {
                margin: 0;
            }

            .csv-discounted-products-loading {
                height: 1rem;
                width: 1rem;
            }
        }

        .csv-discounted-products-bulk-remove {
            &:hover {
                border-color: red;
                color: red;
            }
        }
    }

    .csv-discounted-products-labels {
        display: grid;
        grid-template-rows: 1;
        grid-template-columns: 10px 1fr 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr;
        padding: 0.5rem 1rem;
        background-color: rgb(224, 224, 224);
    }

    .csv-discounted-products {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding: 0.5rem;

        .csv-discounted-product {
            display: grid;
            grid-template-rows: 1;
            grid-template-columns: 10px 1fr 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr;
            height: 5rem;
            align-items: center;
            border: 1px solid grey;
            padding: 0.5rem;

            .csv-discounted-product-image-container {
                height: 4rem;
                object-fit: cover;
                display: flex;
                justify-content: center;
                align-items: center;

                .csv-discounted-product-image {
                    height: 4rem;
                    width: 2.6rem;
                    object-fit: cover;
                }
            }

            .csv-discounted-product-remove-button {
                &:hover {
                    border-color: red;
                    color: red;
                }
            }
        }
    }
}