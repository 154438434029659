.container {
    padding: 0 10px;
}

.order-form {
    width: 100%;
}

.order-form-top {
    text-align: start;
    width: 100%;
    border: 1px solid black;
    padding: 10px;
    margin-bottom: 10px;
    display: grid;
    gap: 10px;
    grid-template-columns: 3fr 2fr;
}

.cart-form-top-bar {
    display: inline-flex;
    width: 100%;
    padding: 0px 20px 0px;
    justify-content: space-between;
}

.cart-form-sort {
    width: 150px;
}

.payment-type {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    .payment-selection {
        width: 100%;
    }

    .installment {
        width: 40%;
        margin-top: 35px;
        margin-left: 20px;
    }
}



.new-invoice-btn {
    margin: 0px 0px 20px
}

.order-form-left, .order-form-right {
    padding: 10px;
    border: 1px solid black;
}

.invoice {
    border: 1px solid black;
    border-radius: 2px;
    width: 170px;
    height: 30px;
}

.invoices {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.close-price {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    justify-self: flex-end;
}

.cart_item_order {
  display: grid;
  grid-template-columns: 4fr 10fr 3fr;
  gap: 20px;
  border: 1px solid black;
  padding: 10px;
  margin-bottom: 10px;
}


.address-box {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    margin: 0 -10px;
    padding: 10px;
}

.experiments-box {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
}

.experiments-box .experiment-container {
    font-size: 0.8rem;
}

.experiments-box .experiment-container .experiment-key {
    font-weight: 600;
}
 
.inline_inputs_edit_order {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    margin-top: 20px;
}
.get_optimal_cargo_button {
    margin-bottom: 15px;
    padding: 5px 5px 5px 0;
}

.inline_three_inputs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
}

.header-save {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}

.too-old {
    font-size: 40px;
    margin: 20vh auto;
}

.button-margin {
    margin-right: 10px;
}

.calculate-button {
    padding: 5px;
}

.input-width {
    width: 100%;
}

.select-input-width {
    width: 40%;
}
.inline_four_inputs_order {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    gap: 10px;
    width: 111%;
}

.inline_five_inputs_order {
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 1fr 1fr;
    grid-gap: 10px;
    gap: 10px;
    width: 111%;
}
.inline_four_inputs_order > .ant-input-number-affix-wrapper-disabled {
    width: 100%;
}
.cart-item-notes {
    display: flex;
    align-items: center;
}

.select-input-width-px {
    width: 150px;
}

.text-area-height {
    height: 250px;
}

.status-save {
    display: flex;
    flex-direction: row;
}

.status-save > div {
    width: 100%;
}

.status-save-button {
    margin-top: 30px;
    margin-left: 20px;
}

.cart_item_image {
    max-width: 200px;
    overflow: hidden;
}

.ant-picker {
    display: flex;
}

.add-new-product {
    padding: 2rem 1rem 1rem 1rem;
}

select {
    height: 1.8rem;
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 0.4rem;
    padding-left: 1rem;
}

.checkbox-label {
    margin-left: 0.7rem;
}

.sales-agreement-box {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    padding: 0.5rem 0;
    display: flex;
    flex-direction: row;
    gap: 0.2rem;
}

.sales-agreement-box > button {
    width: 49%;
}

.valid-checkboxes {
    display: flex;
    flex-direction: column;
}

.english-invoice-buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.english-invoice-buttons > button {
    width: 100%;
}