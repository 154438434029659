.list-product_type-item {
    margin-top: 5px;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 3fr 3fr 1fr;
    grid-template-areas: "E-check E-name E-status";
    height: 75px;
    border: 1px solid black;
    padding: 0 5px;
  }
  
  .list-product_type-item:hover {
    background-color: rgb(248, 225, 225);
  }
  
  .list-product_type-item-check {
    grid-area: E-check;
  }
  .list-product_type-item-name {
    grid-area: E-name;
  }
  .list-product_type-item-status {
    grid-area: E-status;
  }
  