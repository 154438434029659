.new-products-container {
  padding: 0 10px;
}
.number-of-products {
    margin-left: 10px;
    margin-top: 10px;
}

.title {
    text-align: left;
    padding-left: 10px;
}

.list-new-products-table-head {
    background-color: rgb(194, 194, 194);
    display: grid;
    grid-template-columns: 1fr 3fr 12fr 4fr 4fr 4fr 2fr 3fr 3fr;
    grid-template-areas: " A-check . A-product A-status A-code A-vendor A-price";
    padding: 10px 0;
  }
  .list-new-products-table-check {
    grid-area: A-check;
  }
  .list-new-products-table-product {
    grid-area: A-product;
  }
  .list-new-products-table-status {
    grid-area: A-status;
  }
  .list-new-products-table-vendor {
    grid-area: A-vendor;
  }
  .list-new-products-table-code {
    grid-area: A-code;
  }
  .list-new-products-table-price {
    grid-area: A-price;
  }

  