.list-carts-container {
  padding: 0 10px;
}
.list-carts-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list-carts-filter {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.cart-filter-form {
  display: grid;
  grid-template-columns: 1fr;
  margin-right: 15px;
}

.cart-filter-form div {
  width: 120px;
}

.search {
  width: 50%
}

.list-carts-table-head {
  background-color: rgb(194, 194, 194);
  display: grid;
  grid-template-columns: 50px repeat(6, 1fr) 30px;
  padding: 10px 0;
}
