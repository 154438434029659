.list-supplier-invoices-container {
    padding: 0 10px;
  }
  .list-supplier-invoices-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
  }
  
  
  .list-supplier-invoices-filter {
    display: flex;
    justify-content: end;
    margin-bottom: 10px;
  }
    
  .supplier-invoice-filter-form {
    display: grid;
    grid-template-columns: 1fr;
    margin-right: -15px;
  }
  
  .supplier-invoice-filter-form div {
    width: 120px;
  }
  
  .search-p {
    width: 20%;
  }
  
  .list-supplier-invoice-table-head {
    background-color: rgb(194, 194, 194);
    display: grid;
    grid-template-columns: 1fr 3fr 3fr 3fr 40px;
    grid-template-areas: "A-check A-code A-vendor A-created-at";
    padding: 10px 0;
  }
  .list-supplier-invoice-table-check {
    grid-area: A-check;
  }
  .list-supplier-invoice-table-code {
    grid-area: A-code;
  }
  .list-supplier-invoice-table-vendor {
    grid-area: A-vendor;
  }
  .list-supplier-invoice-table-created-at {
    grid-area: A-created-at;
  }
  