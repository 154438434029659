.list-shipment-item {
  margin-top: 5px;
  display: grid;
  align-items: center;
  grid-template-columns: 50px repeat(7, 1fr) 30px;
  height: 75px;
  border: 1px solid black;
  padding: 0 5px;
  cursor: pointer;
  color: black;
}

.list-shipment-item:hover {
  text-decoration: none; 
  color: inherit;
}

#trash {
  margin-left: auto;
}
