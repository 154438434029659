.edit-filter-container {

    display: flex;
    flex-direction: column;
    padding: 1rem;

    .edit-filter-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0.8rem 1rem;
        border-bottom: 1px solid black;
    
        .filter-header {
            display: flex;
            
            h2 {
                margin: 0
            }
        }
    }

    .info-container {
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 1rem;
        width: 100%;

        .info-header {
            h3 {
                margin: 0
            }
        }

        .info {
            width: 100%;
            padding: 1rem;
            border: 0.5px solid #1890ff;
            box-shadow: 0px 0px 3px 0.5px #188fff92;
            border-radius: 1rem;

            .translations-container {
                display: grid;
                gap: 1rem;
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }
        }

        .section {
            border: 1px solid rgba(0, 0, 0, 0.175);
            border-radius: 1rem;
            width: 100%;
            min-height: 200px;
            padding: 1rem;

            .inline-inputs-three-to-one {
                display: grid;
                gap: 1rem;
                grid-template-columns: 3fr 1fr;

                .hovered-category {
                    top: -3rem;
                    right: 0;
                }

                .category-trash {
                    display: flex !important;
                    align-items: flex-start;
                }
            }
        }
    }
}