.vendor-banner-single-image {
    width: 104px;
    height: 104px;
    padding: 7px;
    border: 0.5px solid rgb(201, 201, 201);

    img {
        object-fit: contain;
    }
}

.vendor-banner-single-image:hover {
    .vendor-banner-shadow-image {
        opacity: 0.6;
    }

    img {
        opacity: 0.6;
    }
}

.vendor-banner-main-image {
    box-shadow: 0 0 4px 2px green;
}

.vendor-banner-shadow-image {
    background-color: rgb(174, 174, 174);
    width: 100%;
    height: 100%;
    position: relative;
    bottom: 86px;
    left: 0;
    opacity: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .vendor-banner-main-selector, .vendor-banner-trash-selector {
        width: 40%;
        height: 40%;
        cursor: pointer;
        font-size: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .vendor-banner-main-selector {
        color: green;
    }

    .vendor-banner-trash-selector {
        color: blue;
    }


}