.list-refund-item {
  margin-top: 5px;
  display: grid;
  align-items: center;
  grid-template-columns: 4fr 4fr 4fr 4fr 4fr 4fr;
  height: 75px;
  border: 1px solid black;
  padding: 0 5px;
  cursor: pointer;
}
.accounting {
  border-bottom: 0.8px solid black;
  padding: 2px , 10px, 2px, 10px;
  margin-bottom: 10px;
}
.list-refund-item:hover{
  background-color: rgb(248, 225, 225);
}
