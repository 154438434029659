.container {
    padding: 0 10px;
}

.cart-form {
    width: 100%;
}

.cart-form-top {
    text-align: start;
    width: 100%;
    border: 1px solid black;
    padding: 10px 10px 100px 10px;
    margin-bottom: 10px;
}

.cart_item {
    display: grid;
    grid-template-columns: 4fr 13fr 2fr;
    gap: 20px;
    border: 1px solid black;
    padding: 10px;
    margin-bottom: 10px;
}

.cart_item_info {
    text-align: start;
    align-items: center;
}

.close-price {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
 
.inline_inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.inline_three_inputs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
}

.header-save {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}

.cart_item_image {
    max-width: 200px;
    overflow: hidden;
}