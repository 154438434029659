.supplier-authority-main-container {
    display: flex;
    flex-direction: column;
    margin: 0.75rem;
    gap: 0.5rem;
}

.supplier-authority-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.supplier-authority-configuration-container {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.supplier-authority-configuration-input-container {
    text-align: left;
}

.supplier-authority-configuration-input {
    width: 50%;
}

