@import "/src/pages/EditVendor/EditVendor.scss";

.shelves-container {
  padding: 20px;
}

.header {
  float: left;
}

.search-box {
  width: 500px;
  margin: 20px;
}

.shelves-header-save {
@include vendor-header-save;
}

.shelves-tabs {
  @include vendor-tabs;

}

.shelves-top-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 50px;
}

.add-new-box-container {
  display: flex;
  padding-bottom: 50px;
}