.logistics-order-container {
    padding: 0 10px;

    .order-form {
        width: 100%;
    }
    
    .order-form-top {
        text-align: start;
        width: 100%;
        border: 1px solid black;
        padding: 10px;
        margin-bottom: 10px;
        display: grid;
        gap: 10px;
        grid-template-columns: 3fr 2fr;
    }
    
    .cart-form-top-bar {
        display: inline-flex;
        width: 100%;
        padding: 0px 20px 0px;
        justify-content: space-between;
    }
    
    .order-form-left, .order-form-right {
        padding: 10px;
        border: 1px solid black;
    }
    
    .close-price {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        justify-self: flex-end;
    }
    
    .cart_item_order {
      display: grid;
      grid-template-columns: 5fr 1fr;
      gap: 20px;
      border: 1px solid black;
      padding: 10px;
      margin-bottom: 10px;
    }
    
    .address-box {
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        margin: 0 -10px;
        padding: 10px;
    }
     
    .inline_inputs_edit_order {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;
        margin-top: 20px;
    }
    
    .inline_three_inputs {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;
    }
    
    .header-save {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
    }
    
    .button-margin {
        margin-right: 10px;
    }
    
    .calculate-button {
        padding: 5px;
    }
    
    .input-width {
        width: 100%;
    }
    
    .select-input-width {
        width: 40%;
    }
    .inline_four_inputs_order {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 10px;
        gap: 10px;
        width: 111%;
    }
    
    .inline_five_inputs_order {
        display: grid;
        grid-template-columns: 2fr 2fr 2fr 1fr 1fr;
        grid-gap: 10px;
        gap: 10px;
        width: 111%;
    }
    .inline_four_inputs_order > .ant-input-number-affix-wrapper-disabled {
        width: 100%;
    }
    
    .select-input-width-px {
        width: 150px;
    }
    
    .text-area-height {
        height: 250px;
    }
    
    .status-save {
        display: flex;
        flex-direction: row;
    }
    
    .status-save > div {
        width: 100%;
    }
    
    .status-save-button {
        margin-top: 30px;
        margin-left: 20px;
    }
    
    .ant-picker {
        display: flex;
    }
    
    .add-new-product {
        padding: 2rem 1rem 1rem 1rem;
    }
    
    select {
        height: 1.8rem;
        width: 100%;
        border: 1px solid #d9d9d9;
        border-radius: 0.4rem;
        padding-left: 1rem;
    }
    
    .checkbox-label {
        margin-left: 0.7rem;
    }
    
    .valid-checkboxes {
        display: flex;
        flex-direction: column;
    }
    
    .english-invoice-buttons {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }
    
    .english-invoice-buttons > button {
        width: 100%;
    }
}

.error-display {
    border-color: red;
}
