.refund-details {
    padding: 0 10px;
}

.form-top-refund {
    width: 100%;
    border: 1px solid gray;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
}
.refund-form {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
}
.refund-image-form-container {
    display: flex;
    gap: 20px;
} 

.refund-near-image-fields {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 10px;
    width: 100%;
}

.refund-image-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: flex-start;
    margin-top: 15px;
    overflow: auto;
    max-height: 230px;
}

/* .refund-image-container > ant-image {
    width: 200px;
    overflow: hidden scroll;

} */
.download-button {
    position: absolute;
    top: 0;
    left: 1rem;
    width: max-content;
    margin-right: 10px;
  }

.coupon-details {
    border: 1px solid black;
    margin-top: 20px;
    border-radius: 10px;
    padding: 5px 20px;
    margin-left: 20px;
    margin-right: 20px;
}


.inline-inputs-refund {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    gap: 10px;
    margin-top: 0.5rem
}

.inline-inputs-refund-margin {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    gap: 10px;
    margin-top: -1rem;
}

.refund-buttons {
    margin-top: 20px;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}
.refund-buttons > .approve {
    background-color: rgb(19, 174, 37);
    border-color: rgb(30, 255, 56);
    color: rgb(255, 255, 255);
}

.refund-buttons > .approve:hover {
    background-color: rgb(76, 169, 87);
}

.refund-buttons > .disapprove {
    background-color: rgb(255, 30, 30);
    border-color: rgb(255, 30, 30);
}

.refund-buttons > .disapprove:hover {
    background-color: rgb(255, 97, 97);;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.custom-file-upload input[type="file"] {
    display: none;
}

.refund-form-bottom {
    max-height: 10rem;
    overflow: hidden scroll;
}

.refund-form-bottom > .single-image {
    position: relative;
}

.refund-form-bottom > .download-button {
    display: block;
}

.checkbox-container-refund {
    display: flex;
}

.custom-product-selector {
    .ant-select-selector {
        height: 100px !important;
    }
}

.add-product-selection{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px
}

.add-product-reasons{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .ant-form-item{
        margin-bottom: 5px !important;
    }
}

.accounting {
    border-bottom: 0.8px solid black;
    padding: 2px , 10px, 2px, 10px;
    margin-bottom: 5px;
  }

.free-return-tag {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    span{
        background-color: green;
        padding: 0.3rem 1rem;
        color: white;
    }
}

.order-accounting {
    border: 0.5px solid gray;
    border-radius: 10px;
    padding: 10px;
    margin: 0 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
}

.order-accounting-inputs {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    gap: 10px;
}

.order-accounting-invoice {
    width: 100% !important;
}

.order-accounting-return-amount {
    width: 100%;
}

.order-accounting-header {
    font-size: 1.25rem;
    font-weight: 500;
    align-self: center;
    margin-bottom: 1rem;
}