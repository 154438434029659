.supplierApplication-details {
    padding: 0 10px;
}

.form-top-supplierApplication {
    width: 100%;
    border: 1px solid gray;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
}
.supplierApplication-form {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
}
.supplierApplication-image-container{ 
    display : flex;
    gap : 20px;
    justify-content : flex-start;
    margin-top : 15px; 

}

.download-button {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 10px;
  }

.inline-status-reason-sup{
    overflow:auto; 
    border-top: 1px solid gray;
    margin-top: 20px;
    padding-top: 20px;
    width: 100%;
    display: grid;
    grid-template-areas:
    "a a a b b b"
    "c c c d d d"
    "e e e f f f"
    "g g g g g g"
    "h h h i i i";
    gap: 10px;
}
.inline-status-reason-sup > :nth-child(1) {
    grid-area: a;
}
.inline-status-reason-sup > :nth-child(2) {
    grid-area: b;
}
.inline-status-reason-sup > :nth-child(3) {
    grid-area: c;
}
.inline-status-reason-sup > :nth-child(4) {
    grid-area: d;
}
.inline-status-reason-sup > :nth-child(5) {
    grid-area: e;
}
.inline-status-reason-sup > :nth-child(6) {
    grid-area: f;
}
.inline-status-reason-sup > :nth-child(7) {
    grid-area: g;
}
.inline-status-reason-sup > :nth-child(8) {
    grid-area: h;
}
.inline-status-reason-sup > :nth-child(9) {
    grid-area: i;
}


.inline-two-inputs-supplierApplication {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: 1px solid gray;
    gap: 10px
}


.supplierApplication-buttons {
    margin-top: 20px;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}
.supplierApplication-buttons > .approve {
    background-color: rgb(19, 174, 37);
    border-color: rgb(30, 255, 56);
    color: rgb(255, 255, 255);
}

.supplierApplication-buttons > .approve:hover {
    background-color: rgb(76, 169, 87);
}

.supplierApplication-buttons > .disapprove {
    background-color: rgb(255, 30, 30);
    border-color: rgb(255, 30, 30);
}

.supplierApplication-buttons > .disapprove:hover {
    background-color: rgb(255, 97, 97);;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.custom-file-upload input[type="file"] {
    display: none;
}

