.invoice-products-main-container {
    border: 1px solid lightgray;
    padding: 1rem 1rem;
}

.invoice-products-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.invoice-products-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    max-height: 500px;
    overflow-y: auto;
}

.invoice-product-card {
    position: relative;
    margin-bottom: 20px;

    .invoice-product-card-container {
        min-height: 300px;
        display: flex;
        flex-direction: column; 
        justify-content: center;
        align-items: center;
        img {
            width: 180px;
            height: auto;
            object-fit: cover;
            margin: 10px auto;
            border: 0.2px solid lightgray;
            border-radius: 1rem;
        }
    }
    
}

.invoice-product-card-button {
    position: absolute;
    right: 5px;
    top: 5px;
}

.invoice-product-card .ant-descriptions-item-content {
    font-size: 12px;
    padding: 2px 4px;
}
.invoice-product-card .ant-descriptions-item-label {
    font-size: 14px;
    padding: 4px 8px;
    font-weight: 600;
}

.invoice-product-card .ant-card-head-wrapper {
    padding: 0;
}

.invoice-product-card .ant-btn-secondary {
    background-color: darkred;
    color: white;
}
