.list-supplier-invoice-item {
    margin-top: 5px;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 3fr 3fr 3fr 40px;
    grid-template-areas: "B-check B-code B-vendor B-created-at";
    height: 75px;
    border: 1px solid black;
    padding: 0 5px;
    cursor: pointer;
  }
  .list-supplier-invoice-item:hover{
    background-color: rgb(248, 225, 225);
  }
  
  .list-supplier-invoice-item-check {
    grid-area: B-check;
  }
  .list-supplier-invoice-item-code {
    grid-area: B-code;
  }
  .list-supplier-invoice-item-vendor {
    grid-area: B-vendor;
  }
  
  .list-supplier-invoice-item-created-at {
    grid-area: B-created-at;
  }
  
  
  #trash {
    margin-right: 25px;
  }
  
  