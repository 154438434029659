.edit-static-content-container {
    display: flex;
    flex-direction: column;

    .edit-static-content-header {
        width: 100%;
        text-align: left;
        border-bottom: 1px solid grey;
        padding: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        h2 {
            margin: 0;
        }

        .edit-static-content-header-buttons {
            display: flex;
            flex-direction: row;
            gap: 0.4rem;
        }
    }

    .homepage-top-vendors, .homepage-top-categories {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1rem;

        .homepage-top-vendor-item {
            width: 100%;
            border: 1px solid rgb(227, 227, 227);
            border-radius: 0.3rem;
            padding: 1rem;

            .homepage-top-vendor-item-header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .homepage-top-vendor-item-header-buttons {
                    display: flex;
                    flex-direction: row;
                    gap: 0.4rem;

                    .homepage-top-vendor-item-remove {
                        background-color: rgb(228, 36, 36);
                        border-color: rgb(228, 36, 36);
                        color: white;
    
                        &:hover {
                            border-color: rgb(177, 18, 18);;
                        }
                    }
                }
            }

            .homepage-top-vendor-item-fields {
                display: flex;
                flex-direction: row;
                gap: 1rem;

                .left-part {
                    width: 25%;
                    display: flex;
                    justify-content: flex-start;
                }

                .right-part {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;

                    h3 {
                        text-align: left;
                    }

                    .sort-order-input {
                        width: 600px;
                    }
                }
            }

            .descriptions {
                margin-top: 1rem;
                display: flex;
                flex-direction: row;
                gap: 0.5rem;
                flex-wrap: wrap;

                .description {
                    width: 48%;
                }
            }
        }
    }
}

.updated-description {
    box-shadow: 0 0 2px 2px rgba(252, 173, 26, 0.654);
}

.vendors-sorting-container {
    display: grid;
    gap: 0.3rem;
    list-style: none;
    padding: 1rem;
    grid-template-columns: repeat(1, 1fr);

    .vendors-sorting-item {
        padding: 0.3rem 0.5rem;
        text-align: left;
        border-radius: 0.3rem;
        border: 1px solid rgb(111, 44, 98);
        font-weight: 500;
        width: 400px;
    }
}