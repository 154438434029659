.list-vendors-container {
  padding: 0 10px;
}
.list-vendors-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list-vendors-filter {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.list-vendors-dropdown-filter {
  display: flex;
  gap: 2rem;
}

.search {
  width: 50%
}

.list-vendors-table-head {
  background-color: rgb(194, 194, 194);
  display: grid;
  grid-template-columns: 1fr 3fr 12fr 4fr 4fr 4fr 2fr;
  grid-template-areas: " C-check . C-vendor C-status C-handle C-user .";
  padding: 10px 0;
}
.list-vendors-table-check {
  grid-area: C-check;
}
.list-vendors-table-vendor {
  grid-area: C-vendor;
}
.list-vendors-table-status {
  grid-area: C-status;
}
.list-vendors-table-handle {
  grid-area: C-handle;
}
.list-vendors-table-user {
  grid-area: C-user;
}
