.list-refunds-container {
  padding: 0 10px;
}
.list-refunds-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list-refunds-filter {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.refund-search {
  width: 30%;
  margin-right: 1rem;
}

.refund-filter-form {
  display: flex;
  justify-content: flex-end;
  row-gap: 0.5rem;
  .filter-refunds {
    min-width: 150px;
    background-color: lightblue;
  }
}

.search-p {
  width: 20%;
}

.list-refund-table-head {
  background-color: rgb(194, 194, 194);
  display: grid;
  grid-template-columns: 4fr 4fr 4fr 4fr 4fr 4fr;
  padding: 10px 0;
}

.sort-filter{
  width: 180px;
}





