.list-logistics-orders-container {
  padding: 0 10px;

  .list-orders-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .list-orders-top-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .list-orders-filter {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 10px;
    gap: 10px;
  }
  
  .order-filter-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 0.5rem;;
  }
  
  .orders-search-areas {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 40%;
  }
  
  .orders-search {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5px;
  }
  
  .list-orders-table-head {
    background-color: rgb(194, 194, 194);
    display: grid;
    grid-template-columns: 25px 3fr 3fr 3fr 2fr 3fr 3fr 3fr 3fr 3fr 3fr 30px;
    gap: 10px;
    padding: 10px 0;
  }
  
  .bulk-invoice-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 5px;
    margin-right: 10px;
  }
  
  .list-orders-number {
    text-align: left;
    margin-bottom: 5px;
  }
  
  .list-orders-header {
    display: block;
    position: sticky;
    top: 0;
    z-index: 10;
    background: #fff;
  }
  
  .list-orders-footer {
    display: block;
    position: sticky;
    bottom: -15px;
    z-index: 10;
    background: #fff;
  }
}