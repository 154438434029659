.marketing-flows-container {
    .marketing-flows-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid grey;
        padding: 0.5rem 1rem;
        text-align: left;

        .marketing-flows-header-left {   
            display: flex;
            flex-direction: row;
            gap: 1rem;
            align-items: center;
    
            h2 {
                margin: 0;
            }
        }

        .marketing-flows-header-right {
            display: flex;
            flex-direction: row;
            gap: 1rem;
        }
    }

    .marketing-flows-list {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding: 0.5rem;

        .marketing-flow-header {
            display: grid;
            grid-template-columns: 1fr 2fr 2fr 2fr 2fr 2fr;
            background-color: rgba(107, 32, 76, 0.195);
            padding: 1rem 0;
            font-weight: 600;
        }

        .marketing-flow {
            display: grid;
            grid-template-columns: 1fr 2fr 2fr 2fr 2fr 2fr;
            padding: 1rem 0;
            border: 0.5px solid rgba(128, 128, 128, 0.318);
            cursor: pointer;
            color: black;

            &:hover {
                border: 0.5px solid black;
            }

            .marketing-flow-checkbox-container {
                margin: 0 auto;
            }
        }
    }
}

.checkbox-countries {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem 2rem;
}

.horizontal-two-inputs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 2rem;
    align-items: center;
}

.marketing-flow-details {
    padding: 1rem;
}

.ant-radio-button-wrapper-checked {
    border: 1px solid rgb(58, 162, 251) !important;
    z-index: 10 !important;
    color: rgb(58, 162, 251) !important;
}