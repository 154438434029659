.list-coupon-item {
  margin-top: 5px;
  display: grid;
  align-items: center;
  grid-template-columns: 50px repeat(6, 1fr) 30px;
  height: 75px;
  border: 1px solid black;
  padding: 0 5px;
  cursor: pointer;
}
.list-coupon-item:hover {
  background-color: rgb(248, 225, 225);
}

#trash {
  margin-left: auto;
}
