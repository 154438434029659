.list-credit-status-container {
    padding: 0 10px;
}
.list-credit-status-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.list-credit-status-filter {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}

.list-credit-status-search {
    width: 30%;
}

.credit-status-filter-form {
    display: grid;
    grid-template-columns: 1fr;
    margin-right: 25px;
    width: 22%;
}

.credit-status-filter-div {
    width: 220px !important;
}

.search {
    width: 50%
}

.list-credit-status-table-head {
    background-color: rgb(194, 194, 194);
    display: grid;
    grid-template-columns: 25px 1fr 1fr 1fr 1fr 2fr 30px;
    gap: 10px;
    padding: 10px 0;
}
  