.list-refund-products-container {
  padding: 0 10px;
}
.list-refund-products-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.list-refund-products-filter {
  display: flex;
  justify-content: flex-end;
  column-gap: 0.5rem;
}

.refund-products-search {
  width: 23%;
}

.refund-products-filter-form {
  display: flex;
}

.search-p {
  width: 20%;
}

.list-refund-products-table-head {
  background-color: rgb(194, 194, 194);
  display: grid;
  grid-template-columns: 4fr 4fr 4fr 4fr 4fr 4fr;
  padding: 10px 0;
}

.filter-refund-products{
  width: 150px;
}





