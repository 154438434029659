.list-cargo-companies-container {
  padding: 0 10px;
}
.list-cargo-companies-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list-cargo-companies-filter {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.search {
  width: 50%
}

.search-bar {
  display: flex;
}

.search-bar > input {
  border: none;
  width: 100%;
}

.list-cargo-companies-table-head {
  background-color: rgb(194, 194, 194);
  display: grid;
  grid-template-columns: 1fr 3fr 12fr 4fr 4fr 4fr 2fr;
  grid-template-areas: " C-check . C-cargo-company C-status C-handle C-user .";
  padding: 10px 0;
}
.list-cargo-companies-table-check {
  grid-area: C-check;
}
.list-cargo-companies-table-cargo-company {
  grid-area: C-cargo-company;
}
.list-cargo-companies-table-status {
  grid-area: C-status;
}
.list-cargo-companies-table-handle {
  grid-area: C-handle;
}
.list-cargo-companies-table-user {
  grid-area: C-user;
}
