.container {
    padding: 0 10px;
}

.cargo_company-border {
    border-top: 1px solid black;
    margin-top: 20px;
    padding-top: 20px;
}

.form-top-cargo_company {
    width: 100%;
    border: 1px solid black;
    padding: 20px;
    display: flex;
    flex-direction: column;
}
.inline-five-inputs-cargo_company {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 10px
}

.sub-title {
    grid-column: span 3;
    text-align: left;
}

.inline-four-inputs-plus-cargo_company {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px
}

.inline-three-inputs-cargo_company {
    width: 100%;
    display: grid;
    overflow:auto; 
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px
}

.country-groups-header {
    display: flex;
    justify-content: space-between;
}

.inline-two-inputs-cargo_company {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px
}

.cargo_company-form {
    width: 100%;
}

.founding-year {
    width: 100%;
}

.image {
    width: 100%;
    padding: 20px;
    margin: auto;
} 

.image-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.image-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    border-top: 1px solid black;
    margin-top: 20px;
    padding-top: 20px;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.custom-file-upload input[type="file"] {
    display: none;
}

.order-emails-header {
    font-weight: 700;
    text-align: left;
}

.saved-order-emails {
    margin-bottom: 30px;
    width: 66.33%;
}

.new-order-email {
    margin-bottom: 30px;
}

.new-order-email > p {
    text-align: left;
}

.new-order-input {
    width: calc(100% + 140px);
    text-align: left;
}

.hovered-category {
    background-color: #f0f0f0;
    padding: 10px;
    margin-top: 10px;
    border-radius: 4px;
    color: black;
    position: absolute;
    top: -130px;
    z-index: 999999;
  }

  .inline-two-inputs-cargo_company-title {
    overflow:auto;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px
}

.country-groups {
    border: 0.5px solid black;
    margin: auto;
    max-width: 1000px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    cursor: default;
}

@media (max-width: 998px) {
    .country-groups {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (max-width: 768px) {
    .country-groups {
        grid-template-columns: 1fr;
    }
}

.country-group {
    padding: 10px;
    text-align: center;
    cursor: pointer;
    border: 0.5px solid black;
    height: 210px;
    overflow: hidden scroll;
    cursor: default;
}
  
.country-group-title {
    font-weight: 700;
}

.country-group-header {
    display: flex;
    align-items: center;
    justify-content: center;
}

.country-group-buttons {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-weight: 700;
    font-size: large;
}

.country-group-buttons:hover {
    text-decoration: underline;
}

.country-group-country {
    display: flex;
    align-items: center;
    justify-content: center;
}
.country-group-country-reversed {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
}