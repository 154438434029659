.container {
    padding: 0 10px;
}

.invoice-form {
    width: 100%;
}

.form-top {
    width: 100%;
    display: grid;
    gap: 10px;
    grid-template-columns: 3fr 2fr;
}

.inline-inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px
}

.form-left, .form-right {
    padding: 10px;
    border: 1px solid black;
}

.form-right {
    width: 100%;
    overflow: hidden;
}

.header-save {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}

.buttons {
    display: flex;
    gap: 10px;
}

.ant-form input[type="file"] {
    display: none;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.invoice-img-container {
    overflow: hidden;
}

.invoice-img-container > iframe {
    width: 100%;
    height: 300px;
}

.invoice-img-container > img {
    width: 100%;
}