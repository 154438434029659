.order-track-search-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
}


.order-track-search-wrapper .order-track-search-input {
    width: 100%;
}

form .order-track-search-area {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: solid 1px black;
}

.order-track-search-area input {
    width: 90%;
    outline: none;
    border: none;
    height: 30px;
}

.order-track-search-area .search-icon {
    margin-left: 5px;
    font-size: 15px;
    color: #000;
}

.order-track-search-area .x-icon {
    margin-right: 5px;
    font-size: 15px;
    color: black;
    cursor: pointer;
}

form .search-results {
    position: absolute;
    background-color: rgb(255, 255, 255);
    width: 100%;
    border-bottom: 1px solid #000000;
    border-right: 1px solid #000000;
    border-left: 1px solid #000000;
    z-index: 100;
}

.hidden {
    overflow: hidden;
}