.test-mode-switch-container {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    width: 100%;
    height: 32px;
    padding-left: 1rem;
    margin-top: 0.4rem;
    padding-right: 1rem;
    &:hover {
        border-color: #40a9ff;
        color: #40a9ff;
        border-right-width: 1px !important;
    }

}

.test-mode-switch-label {
    padding-right: 10px;
    font-size: 14px;

}
