.list-customer-item {
  margin-top: 5px;
  display: grid;
  align-items: center;
  grid-template-columns: 25px 1fr 1fr 2fr 2fr 2fr 30px;
  min-height: 75px;
  gap: 10px;
  border: 1px solid black;
  padding: 0 5px;
  cursor: pointer;
  color: black;
}
.list-customer-item:hover {
  background-color: rgb(248, 225, 225);
}

#trash {
  margin-left: auto;
}

.list-customer-item div {
  overflow: auto;
  overflow-wrap: break-word;
}
