.supplier_stock_code {
    margin-top: 20px;
}

.stock-box {
    padding: 15px 10px;
    margin: 0 -10px;
}




.product-group-item:hover{
  background-color: rgb(248, 225, 225);
}
.product-group-item {
    cursor: pointer;
}

.error-display {
    border-color: red;
    .ant-select .ant-select-selector {
        border-color: red !important;
    }
}