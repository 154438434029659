.lonca-invoices-main-container {
    padding: 0 10px;
}

.lonca-invoices-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
}

.lonca-invoices-filters {
    display: flex;
    justify-content: end;
    margin-bottom: 10px;
    margin-right: 10px;
    gap: 10px;
}

.lonca-invoices-table-row {
    cursor: pointer;
}

.lonca-invoices-form-item .ant-form-item-label > label {
    font-weight: bold;
    font-size: 14px;
}

.lonca-invoices-form-item .ant-select {
    width: 150px;
}

.lonca-invoices-form-item .ant-input {
    width: 150px;
}

.lonca-invoices-form-item .ant-picker {
    width: 150px;
}