.list-application-filter-supplier {
  display:flex;
  justify-content: space-between;

}

.list-application-item-supplier {
    margin-top: 5px;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 4fr 4fr 4fr 4fr 1fr;
    grid-template-areas: "B B-company B-applicant B-date B-status";
    height: 75px;
    border: 1px solid black;
    padding: 0 5px;
    cursor: pointer;
  }
  .list-application-item-supplier:hover{
    background-color: rgb(248, 225, 225);
  }
  .list-application-item-supplier-applicant {
    grid-area: B-applicant;
  }
  .list-application-item-supplier-company {
    grid-area: B-company;
  }
  .list-application-item-supplier-status {
    grid-area: B-status;
  }
  .list-application-item-supplier-date {
    grid-area: B-date;
  }
  