.list-applications-container {
    padding: 0 10px;
  }
  .list-applications-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .list-applications-filter {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    justify-content: space-between; 
  }

  
  .search-p {
    width: 20%;
  }
  
  .list-application-table-head {
    background-color: rgb(194, 194, 194);
    display: grid;
    grid-template-columns:1fr 4fr 4fr 4fr 4fr 1fr;
    grid-template-areas: "A A-company A-applicant A-date A-status";
    padding: 10px 0;
  }

  .list-application-table-applicant {
    grid-area: A-applicant;
  }
  .list-application-table-company {
    grid-area: A-company;
  }
  .list-application-table-date {
    grid-area: A-date;
  }
  .list-application-table-status {
    grid-area: A-status;
  }