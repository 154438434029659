.list-order-status-item {
  margin-top: 5px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 40px 3fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr;
  height: 75px;
  border: 1px solid black;
  gap: 10px;
  padding: 0 5px;
  cursor: pointer;
  text-decoration: none;
  color: black;

  
}

  

.list-order-status-item:hover {
  background-color: rgb(248, 225, 225);
}

#trash {
  margin-left: auto;
}

.logistics-order {
  background-color: rgb(246, 246, 76);
}