.list-product_types-container {
    padding: 0 10px;
  }
  .list-product_types-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .list-product_types-filter {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
  }
  
  .product_types-filter-form {
    display: grid;
    grid-template-columns: 1fr;
    margin-right: -15px;
    width: 15%;
  }
  
  .search {
    width: 50%
  }
  
  .list-product_types-table-head {
    background-color: rgb(194, 194, 194);
    display: grid;
    grid-template-columns: 1fr 3fr 3fr 1fr;
    grid-template-areas: " D-check D-name D-status";
    padding: 10px 0;
  }
  .list-product_types-table-check {
    grid-area: D-check;
  }
  .list-product_types-table-name {
    grid-area: D-name;
  }
  .list-product_types-table-status {
    grid-area: D-status;
  }
  
  
  