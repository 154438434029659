.container {
    padding: 0 10px;
}

.product-form {
    width: 100%;
}

.product-tabs {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    font-size: large;
    margin-left: 1rem;
    cursor: pointer;
    text-shadow: 0 0 1px black;
}

.form-top {
    width: 100%;
    display: grid;
    gap: 10px;
    grid-template-columns: 3fr 2fr;
}

.form-bottom {
    width: 100%;
    margin-top: 50px;
    border: 1px solid black;
    position: relative;

    .download-button {
        position: absolute;
        top: 0;
        right: 0;
        margin: 2px;
    }
}

.form-left, .form-right {
    padding: 10px;
    border: 1px solid black;
}

.multi-item {
    border: 1px solid black;
    border-radius: 2px;
    width: 100px;
}

.multi-container {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0px 10px;
}

.description_box {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    margin: 0 -10px;
    padding: 10px;
}

.supplier_link_box {
    border-top: 1px solid black;
    margin: 0 -10px;
    padding: 10px;
}

.description_box-cogs {
    border-bottom: 1px solid black;
    margin: 0 -10px;
    padding: 10px;
}

.measurements-box {
  padding: 15px 10px;
  border-bottom: 1px solid black;
  margin: 0 -10px;
}

.stock-box {
    padding: 15px 10px;
    border-top: 1px solid black;
    margin: 0 -10px;

    .add-stock-box-button {
        margin-top: 1rem;
    }

    .stock-box-owner {
        grid-column: span 3;
    }

    .ant-form-item{
        margin-bottom: 10px;
    }
}

.bottom {
    border: none;
    border-bottom: 1px solid black;
}

.in_transit_stock_box {
    padding: 15px 10px;
    border-top: 1px solid black;
    margin: 0 -10px;

    .add-transit-info-button {
        margin-top: 1rem;
    }
    
    .in-transition-two-buttons {
        display: flex;
        justify-content: center;
        gap: 20px;

        button:nth-of-type(2) {
            background-color: rgb(117, 15, 15);
        }
    }
}


.scraper-box {
    padding: 15px 10px;
    border-top: 1px solid black;
    margin: 0 -10px;
    text-align: left;
    display: flex;


}

.inline-inputs {
    
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px
}
.inline-inputs-stock-box {
    
    display: grid;
    grid-template-columns: 3fr 3fr 1fr;
    gap: 10px;

    .ant-btn-primary {
        grid-column: 1/ span 1;
    }

    .stock-mini-box {
        width: min-content;
        height: 50px;
        border: 1px solid black;
        justify-self: center;
        align-self: center;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 0.5rem;
    }
}

.inline_three_inputs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px
}
.inline_three_inputs_seized {
    display: grid;
    grid-template-columns: 3fr 3fr 1fr ;
    gap: 5px
}

.header-save {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.product-group-title {
    font-size: 14px;
}

.product-group-box{
    padding-top: 15px;
    padding: 5px;
    text-align: left;
}
.scraper-group-box{
    padding-top: 15px;
    padding: 5px;
    text-align: left;

}
.product-group-container {
    border: 1px solid black;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    border-radius: 4px;
    min-height: 180px;
}

.scraper-group-container {
    border: 1px solid black;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    border-radius: 4px;
    min-height: 180px;
}

.product-group-item {
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 4px;
    width: 100px;
    height: min-content;
    margin: 5px;
    padding: 0px 5px 0px;
}
.bold-text {
  font-weight: bold;
}

.scraper-group-item {
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 4px;
    width:auto;
    height: min-content;
    margin: 5px;
    padding: 0px 5px 0px;
}
.scraper-group-item.faded {
    opacity: 0.5;

  }
.scraper-group-item-content {
    margin-bottom: 10px;
    font-size: 14px;

  }

.product-group-item-color{
    font-weight: bold;
}
.important-x {
    color: red;
}

.product-group-item-X {
    text-align: right;
    font-weight: bolder;
    font-size: large;
    cursor: pointer;
    width: min-content;
    align-self: flex-end;
}

.product-group-item-disabled {
    cursor: not-allowed;
    color: blue;
  }

  .scraper-button-area {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;

}


.product-group-add {
    margin-top: 5px;
    display: inline-flex;
    justify-content: stretch;
    width: 100%;

    .select-and-add {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        justify-content: flex-start;
    }
}

.product-group-add button {
    margin-left: 5px;
}

.button-margin {
    margin-right: 10px;
}

.input-width {
    width: 100%;
}

.left-align-paragraph {
    text-align: left;
}

.plus-outlined-margin {
    margin-top: 8px;
}

.single-image {
    width: 104px;
    height: 104px;
    padding: 7px;
    border: 0.5px solid rgb(201, 201, 201);

    img {
        object-fit: contain;
    }
}

.single-image:hover {
    .shadow-img {
        opacity: 0.6;
    }

    img {
        opacity: 0.6;
    }
}

.main-img {
    box-shadow: 0 0 4px 2px green;
}

.shadow-img {
    background-color: rgb(174, 174, 174);
    width: 100%;
    height: 100%;
    position: relative;
    bottom: 86px;
    left: 0;
    opacity: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .main-selector, .trash-selector {
        width: 40%;
        height: 40%;
        cursor: pointer;
        font-size: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .main-selector {
        color: green;
    }

    .trash-selector {
        color: blue;
    }


}

.checkbox-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .checkbox-container label {
    display: flex;
    align-items: center;
    margin-right: 10px; /* Adjust the margin as per your preference */
  }
  
  .checkbox-container input[type="checkbox"] {
    margin-right: 5px; /* Adjust the margin as per your preference */
  }