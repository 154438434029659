.file-row {
    display: flex;
    flex-wrap: wrap;
  }
  
  .file-item {
    width: 300px;
    height: 400px;
    margin: 5px;
    padding: 10px;
    border: 1px solid #ccc;
  }
  