.list-service-item {
  margin-top: 5px;
  display: grid;
  align-items: center;
  grid-template-columns: 25px 3fr 3fr 3fr 3fr 3fr 4fr 4fr 30px 1fr;
  height: 75px;
  border: 1px solid black;
  padding: 0 5px;
  grid-gap: 10px;
}
.list-service-item:hover {
  background-color: rgb(248, 225, 225);
}

#trash {
  margin-left: auto;
}
  