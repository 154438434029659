.inline-inputs-refund {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    gap: 10px;
    margin-top: 0.5rem
}

.exchange-cart-item-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.margin{
    margin-top:  -0.5rem;
}

.checkbox-container-refund {
    display: flex;
}


.tab-header-container {
    display: flex;
    justify-content: center; 
    gap: 20px;
}
  
.tab-header-container h3 {
    cursor: pointer;
    padding: 10px 20px;
    margin: 0;
    border-bottom: 2px solid transparent; /* Initially no border */
}
  
.tab-header-container h3.active {
    border-bottom-color: #007bff; /* Color for active tab */
}
  
.tab-content-container {
    margin-top: 20px;
}
  
.tab-content-container > div {
    display: none; /* Initially hide all tab content */
}

.tab-content-container > div.show {
    display: block; /* Display active tab content */
}

.tab-headers {
    border-bottom: 0.8px solid black;
    padding: 2px , 10px, 2px, 10px;
    margin-bottom: 0px;
}
