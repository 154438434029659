.item-image {
    height: 348px;
    overflow: hidden;
    width: 250px;
}

.item-image img {
    height: 100%;
    width: auto;
    object-fit: contain;
}

.all-product-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.all-item-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 10px;
}

.all-info-row {
    display: grid;
    grid-template-columns: 4fr 3fr 3fr 3fr;
    justify-content: space-around;
    align-items: center;
    width: 95%;
}
.all-info-row {
    display: grid;
    grid-template-columns: 4fr 3fr 3fr 3fr;
    justify-content: space-around;
    align-items: center;
    width: 95%;
}

.all-product-info-item {
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.all-form-bar {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
}
  
.all-form-label-bar {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
}

.all-search-bar-empty {
    margin: 0px 10px;
}

.all-search-bar {
    width: 100%;
}

.filter-container {
    width: 20%;
}