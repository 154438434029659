.list-filters-container {
    padding: 0 10px;
}
.list-filters-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.list-filters-filter {
    padding: 10px 0;
    display: grid;
    gap: 10px;
    grid-template-columns: 500px 1fr;
}

.list-filters-table-head {
    background-color: rgb(194, 194, 194);
    display: grid;
    grid-template-columns: 40px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 40px;
    padding: 10px 0;
}
  
  