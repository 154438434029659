.product-details-container {
    display: flex;
    flex-direction: column;

    .product-details-top-bar {
        padding: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid black;
        align-items: center;

        .product-details-header {
            margin: 0;
        }

        .product-details-top-btns {
            display: flex;
            flex-direction: row;
            gap: 1rem;
        }
    }
    
    .product-details-filters {
        padding: 0.4rem 1rem;
        display: flex;
        flex-direction: row;
        gap: 0.5rem;

        .product-details-search-bar {
            width: 40%;
        }

        .product-details-filter {
            width: 20%;
        }

        .wider-product-details-filter {
            width: 33%;
        }
    }

    .product-details-bottom-labels {
        padding: 0 1rem;
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        margin-bottom: -0.4rem;

        .wider-product-details-filter {
            width: 33%;
        }
    }

    .product-details-filter-labels {
        display: flex;
        flex-direction: row;
        padding: 0 1rem;
        gap: 0.5rem;
        margin-top: 0.3rem;
        margin-bottom: -0.5rem;

        .product-details-search-bar {
            width: 40%;
        }

        .product-details-filter {
            width: 20%;
        }
    }

    .product-details-tags-filter {
        width: 30%;
        padding: 0 0.3rem 0 0;
    }

    .product-details-items {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .product-details-item {
            border: 1px solid grey;
            border-radius: 0.3rem;
            overflow: hidden;
            display: flex;
            flex-direction: row;

            &.changed-product-details-item {
                box-shadow: 0 0 2px 2px rgba(252, 173, 26, 0.654);
            }

            .product-details-item-image-container {
                width: 25%;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                border-right: 1px solid grey;
                position: relative;

                .product-details-item-image-left-icon {
                    position: absolute;
                    top: 47%;
                    left: 0.7rem;
                    z-index: 500;
                    font-size: 1.5rem;
                    cursor: pointer;
                }

                .product-details-item-image-right-icon {
                    position: absolute;
                    top: 47%;
                    right: 0.7rem;
                    z-index: 500;
                    font-size: 1.5rem;
                    cursor: pointer;
                }

                .product-details-image-navigation-indicators {
                    position: absolute;
                    width: 100%;
                    bottom: 0.3rem;
                    display: flex;
                    flex-direction: row;
                    gap: 0.3rem;
                    z-index: 500;
                    padding: 0 0.3rem;

                    .product-details-image-navigation-indicator {
                        width: 100%;
                        height: 0.3rem;
                        background-color: rgb(179, 179, 179);
                    }

                    .product-details-image-navigation-active-indicator {
                        background-color: rgb(93, 93, 93);
                    }
                }
            }

            .product-details-item-info {
                width: 75%;

                .product-details-item-general-info {
                    padding: 0.5rem;
                    display: flex;
                    flex-direction: column;
                    border-bottom: 1px solid grey;

                    .product-details-item-general-info-row {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        gap: 1rem;

                        .product-details-item-general-info-row-element {
                            width: 24%;
                        }

                        .product-details-item-general-info-row-name-element {
                            width: 50%;
                        }
                    }
                }

                .product-details-descriptions {
                    padding: 1rem;
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;

                    .updated-product-details-description-input {
                        box-shadow: 0 0 2px 2px rgba(252, 173, 26, 0.654);
                    }
                }
            }
        }
    }
}