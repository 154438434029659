.edit-tag-container {

    display: flex;
    flex-direction: column;
    padding: 1rem;

    .edit-tag-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0.8rem 1rem;
        border-bottom: 1px solid black;
    
        .tag-header {
            display: flex;
            
            h2 {
                margin: 0
            }
        }
    }

    .info-container {
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 1rem;
        width: 100%;

        .info-header {
            h3 {
                margin: 0
            }
        }

        .info {
            width: 100%;
            padding: 1rem;
            border: 0.5px solid #1890ff;
            box-shadow: 0px 0px 3px 0.5px #188fff92;
            border-radius: 1rem;

            .translations-container {
                display: grid;
                gap: 1rem;
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }
        }
    }
}