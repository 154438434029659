
.bar-chart-container {
    margin-bottom: 1rem;
    .bar-chart-data-values {
        list-style-type: none;
        margin: 0;
        li {
            display: inline-block;
            margin-right: 3rem;
    
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .bar-chart-total{
        margin-top: 0.5rem;
        margin-bottom: 0;
    }
}