.list-services-container {
  padding: 0 10px;
}
.list-services-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search {
  width: 50%
}

.list-services-table-head {
  background-color: rgb(194, 194, 194);
  display: grid;
  grid-template-columns: 25px 3fr 3fr 3fr 3fr 3fr 4fr 4fr 30px 1fr;
  gap: 10px;
  padding: 10px 0;
}

.filter-form {
  display: flex;
}

.filter-form div {
  width: 110px;
}

.list-services-filter {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
  