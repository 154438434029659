.list-products-container {
  padding: 0 10px;
}
.list-products-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;

  
}
.title {
  text-align: left;
  padding-left: 10px;
}

.list-products-unmatchedFilter {
  display: flex;
  justify-content: space-between;

}
.matchFilter{
  width: 180px;
}
.typeFilter{
  width: 200px;
}



.list-type-table-head {
  background-color: rgb(194, 194, 194);
  display: grid;

  grid-template-areas: "A-check  A-unmatched A-type A-vendor A-dropdown";
  grid-template-columns: 1fr 2fr 2fr 2fr 3fr ;
  padding: 10px 0;
}
.list-type-table-check {
  grid-area: A-check;
}
.list-type-table-unmatched {
  grid-area: A-unmatched;
}

.list-type-table-dropdown {
  grid-area: A-dropdown;
}
.list-type-table-type {
  grid-area: A-type;
}

.list-type-table-vendor {
  grid-area: A-vendor;
}



.list-type-item {
  margin-top: 5px;
  display: grid;
  align-items: center;
  
  grid-template-areas: "B-delete  B-unmatched B-type B-vendor B-dropdown";
  grid-template-columns: 1fr 2fr 2fr 2fr 3fr ;
  height: 75px;
  border: 1px solid black;
  padding: 0 5px;
  cursor: pointer;
}

.changed {
  border: 1px solid Chartreuse;
}
.list-type-item:hover{
  background-color: rgb(248, 225, 225);
}

.list-type-item-delete-button{
  grid-area: B-delete
}

.list-type-item-unmatched {
  grid-area: B-unmatched;
}

.list-type-item-vendor {
  grid-area: B-vendor;
}

.list-type-item-type {
  grid-area: B-type;
}
.list-type-item-dropdown {
  grid-area: B-dropdown;
}

.list-products-filter {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.dropdown-select{
  display: flex;
}

.ant-select-selector{
  width: 100%;
}


.product-group-item-X {
  text-align: right;
  font-weight: bolder;
  font-size: large;
  cursor: pointer;
  width: min-content;
  align-self: flex-end;
}