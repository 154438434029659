.list-products-container {
  padding: 0 10px;
  min-height: 80vh;
}
.list-products-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list-number-of-products {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
} 

.list-products-filter {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.product-filter-form {
  display: grid;
  grid-template-columns: 1fr;
  margin-right: -15px;
}

.product-filter-form div {
  width: 140px;
}

.search-products {
  width: 40%;
}

.list-product-table-head {
  background-color: rgb(194, 194, 194);
  display: grid;
  grid-template-columns: 1fr 3fr 12fr 4fr 4fr 4fr 4fr 2fr 2fr;
  grid-template-areas: " A-check . A-product A-status A-type A-tag A-vendor A-price.";
  padding: 10px 0;
}
.list-product-table-check {
  grid-area: A-check;
}
.list-product-table-product {
  grid-area: A-product;
}
.list-product-table-status {
  grid-area: A-status;
}
.list-product-table-type {
  grid-area: A-type;
}
.list-product-table-tag {
  grid-area: A-tag;
}
.list-product-table-vendor {
  grid-area: A-vendor;
}
.list-product-table-price {
  grid-area: A-price;
}

.search-result-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.inline-inputs-products {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  gap: 10px;
  padding-left: 1rem;
}

.no_invoice {
  grid-column: 4;
}
