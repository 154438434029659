.list-series-container {
  padding: 0 10px;
}
.list-series-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list-series-filter {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.series-filter-form {
  display: grid;
  grid-template-columns: 1fr;
  margin-right: -15px;
}

.search {
  width: 50%
}

.list-series-table-head {
  background-color: rgb(194, 194, 194);
  display: grid;
  grid-template-columns: 1fr 3fr 12fr 4fr 4fr 4fr 2fr;
  grid-template-areas: " D-check D-serie D-serie D-count . . .";
  padding: 10px 0;
}
.list-series-table-check {
  grid-area: D-check;
}
.list-series-table-serie {
  grid-area: D-serie;
}
.list-series-table-count {
  grid-area: D-count;
}


