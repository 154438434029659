.list-tags-container {
  padding: 0 10px;
}
.list-tags-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list-tags-filter {
  padding: 10px 0;
  display: grid;
  gap: 10px;
  grid-template-columns: 500px 1fr;
}

.list-tags-table-head {
  background-color: rgb(194, 194, 194);
  display: grid;
  grid-template-columns: 40px 1fr 1fr 1fr 1fr 40px;
  padding: 10px 0;
}

