.list-order-status-container {
  padding: 0 10px;
}
.list-order-status-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.list-order-status-nav-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.list-order-status-totals{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.list-order-status-nav-right {
  display: flex;
  align-items: center;
}
.list-order-status-nav-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-items: normal;
}

.totals {
  margin-right: 10px;
  margin-top: 10px;
}

.list-order-status-filter {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 10px;
  gap: 1rem;
}

.order-status-filter-form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 10px;
  margin-right: -5px;
}


.order-status-search {
  width: 30%
}

.list-order-status-table-head {
  background-color: rgb(194, 194, 194);
  display: grid;
  grid-template-columns: 1fr 40px 3fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr;
  gap: 10px;
  padding: 10px 10px;
  display: grid;
  align-items: center;
}

.list-order-status-number {
  text-align: left;
  margin-bottom: 5px;
}

.order-info-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 5px;
}

.order-info-table th, .order-info-table td {
  padding: 5px;
  text-align: center;
  border: 1px solid #ddd;
}

.order-info-table thead {
  background-color: #f2f2f2;
}

.order-info-table th {
  background-color: #f2f2f2;
}

.order-info-table td:first-child,
.order-info-table th:first-child {
  text-align: left;
}

.order-info-table td:nth-child(n+2),
.order-info-table th:nth-child(n+2) {
  text-align: center;
}

.order-info-table td {
  font-weight: normal;
}
