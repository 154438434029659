.list-serie-item {
  margin-top: 5px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 3fr 12fr 4fr 4fr 4fr 2fr;
  grid-template-areas: " E-check E-serie E-serie E-count";
  height: 75px;
  border: 1px solid black;
  padding: 0 5px;
}

.list-serie-item:hover {
  background-color: rgb(248, 225, 225);
}

.list-serie-item-check {
  grid-area: E-check;
}
.list-serie-item-serie {
  grid-area: E-serie;
}
.list-serie-item-count {
  grid-area: E-count;
}
