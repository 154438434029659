.container {
    padding: 0 10px;
}

.customer-form {
    width: 100%;
}

.box {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    margin: 0 -10px;
    padding: 10px;

}

.inline_inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.inline_five_inputs {
    display: grid;
    grid-template-columns: 1fr 1fr 1.1fr 1fr 1fr;
    gap: 10px;
    width: 100%;
}

.header-save {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}

.header-buttons {
    margin: 0 30px 0 0 ;
}

.header-buttons > button {
    height: 40px;
    font-size: 1.1rem;
    width: 90px;
}

.error-display {
    border-color: red;
}