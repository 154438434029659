@mixin header-save {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@mixin vendor-header-save {
    background-color: white;
    position: fixed;
    top: 35px;
    z-index: 100;
    width: 75%;
    border-bottom: 1px solid grey;
}
@mixin vendor-tabs {
    display: flex;
    flex-direction: row;
    gap: 1rem;

    div {
        padding: 0.6rem 1.2rem;
        font-size: 1.3rem;
        border-bottom: 3px solid black;

        &:hover {
            background-color: rgba(128, 128, 128, 0.085);
            border-bottom: 3px solid rgb(74, 117, 244);
            color: rgb(74, 117, 244);
            cursor: pointer;
        }
    }

    .selected {
        background-color: rgba(128, 128, 128, 0.085);
        border-bottom: 3px solid rgb(74, 117, 244);
    }
}
.container {
    padding: 0 10px;
}

.vendor-border {
    border-top: 1px solid black;
    margin-top: 20px;
    padding-top: 20px;
}

.form-top-vendor {
    width: 100%;
    border: 1px solid black;
    padding: 20px;
    display: flex;
    flex-direction: column;
}
.inline-five-inputs-vendor {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 10px
}

.inline-four-inputs-plus-vendor {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px
}

.inline-three-inputs-vendor {
    width: 100%;
    display: grid;
    overflow:auto; 
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px
}

.inline-four-inputs-vendor {
    width: 100%;
    display: grid;
    overflow:auto; 
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px
}

.inline-two-inputs-vendor-title {
    overflow:auto;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px
}

.inline-two-inputs-vendor {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px
}
.inline-two-inputs-vendor-long-right {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 10px
}

.vendor-form {
    width: 100%;
}

.founding-year {
    width: 100%;
}

.image {
    width: 100%;
    padding: 20px;
    margin: auto;
} 

.image-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.inline-inputs-four {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;
}

.inline-inputs-five {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;
}

.image-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    border-top: 1px solid black;
    margin-top: 20px;
    padding-top: 20px;
}

.vendor-image-box-container {
    border-top: 1px solid black;
    padding: 2rem 0;
}

.product-type-size-chart-images {
    padding: 1rem;
    border-top: 1px solid black; 

    .product-type-size-chart-images-header {
        text-align: left;
        margin-bottom: 1rem;
    }

    .product-type-size-chart-add-new {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        height: 2rem;
        margin: 2rem 0;
        align-items: center;

        .ant-row {
            margin: 0;
        }

        .ant-select-selector {
            width: 300px;
        }
    }

    .product-type-size-chart-images-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;

        .product-type-size-chart-image-container {
            padding: 1rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            border: 1px solid rgb(226, 226, 226); 
            border-radius: 0.3rem;
            gap: 0.3rem;
            position: relative;

            .product-type-size-chart-image-trash {
                position: absolute;
                top: 0.2rem;
                right: 0.2rem;
                padding: 0.5rem;
                border-radius: 50%;
                width: 2rem;
                height: 2rem;
                border: 1px solid grey;
                display: flex;
                align-items: center;
                cursor: pointer;

                &:hover {
                    background-color: red;
                    color: white;
                    border-color: white;
                }
            }

            .product-type-size-chart-image {
                width: 200px;
                height: 300px;
                display: flex;
                align-items: center;

                img {
                    width: 200px;
                    object-fit: contain;
                }
            }
        }
    }
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.custom-file-upload input[type="file"] {
    display: none;
}

.order-emails-header {
    font-weight: 700;
    text-align: left;
}

.saved-order-emails {
    margin-bottom: 30px;
    width: 66.33%;
}

.new-order-email {
    margin-bottom: 30px;
}

.new-order-email > p {
    text-align: left;
}

.new-order-input {
    width: calc(100% + 140px);
    text-align: left;
}

.hovered-category {
    background-color: #f0f0f0;
    padding: 10px;
    margin-top: 10px;
    border-radius: 4px;
    color: black;
    position: absolute;
    top: -130px;
    z-index: 999999;
}

.header-save {
    @include header-save;
}
.vendor-header-save {
    @include vendor-header-save;
}

.edit-vendor-container {
    margin-top: 5rem;
}

.vendor-tabs {
    @include vendor-tabs;
}
.business-info-upper-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .business-info-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin: 0.2rem 1rem;
        padding: 1rem;

        .section-title {
            font-size: 1.1rem;
            font-weight: 700;
            margin-left: 1rem;
        }

        .section {
            border: 1px solid rgba(0, 0, 0, 0.175);
            border-radius: 1rem;
            width: 100%;
            min-height: 200px;
            padding: 1rem;

            .checkbox-container {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;

                .checkbox-title {
                    margin-right: 1rem;
                }

                .ant-checkbox-wrapper {
                    display: flex;
                    align-items: baseline;
                }

                .ant-radio-group {
                    display: flex;
                    flex-direction: row;
                }

                .ant-form-item {
                    margin-bottom: 0;
                }

                .ant-radio-button-wrapper {
                    margin-right: 0;
                }
            }
        }
    }
}

.seperator {
    width: 95%;
    border-bottom: 1px solid black;
    margin: 1rem;
}

.center-column {
    align-items: center;
}

.add-person-button {
    margin-top: 0.2rem;
}

.people-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    h4 {
        font-weight: 600;
        text-decoration: underline;
    }

    .person-trash {
        display: flex !important;
        align-items: flex-start;
        margin-top: 0.2rem;
    }
}

.abouts-translation-forms-container, .seo_abouts-translation-forms-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: space-around;
    margin-top: 2rem;
    .abouts-translation-container, .seo_abouts-translation-container {
        flex-basis: 48%;

        .language-title {
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;
            align-items: center;
        }
    }
}

.ant-tag-checkable {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 0.3rem;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s;
    margin: 0;

}

.abouts-translation-forms-container {
    margin-bottom: 1rem;
    border-bottom: 1px solid black;
}