.shelve-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; 
  text-align: center; 
  border-radius: 10px;
  margin-top: 10px;
  cursor: pointer;
  color: #1677ff;
  width: 90px;
  height: 250px;
  border-color:  #1677ff;
}

.shelve-container.cancelled {
  border-color: orange !important;
  color: orange !important;
}
.shelve-container.disabled {
  border-color: grey;
  color: grey;
}

.shelve-container.full {
  border-color: red;
  color: red;
}

.shelve-container.not-full-order {
  border-color: green;
  color: green;
}

.shelve-product-info {
  height: 150px;
  width: 80px;
  overflow: hidden scroll;
}

.shelve-modal-stock-info {
  height: 150px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: auto;
  border: 0.5px solid black;
  border-radius: 10px;
  margin-top: 20px;
  overflow: hidden scroll;
  .stock-info {
    text-align: center;
    border-top: 0.5px solid black;
  }
  .stock-info:nth-child(-n+2) {
    border: none;
  }
}

.shelve-stock-title {
  position: relative;
  margin: 0;
  top: -15px;
}

.shelve-modal-exchange-info {
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding: 20px;
  margin: auto;
  border: 0.5px solid black;
  border-radius: 10px;
  margin-top: 20px;
  overflow: hidden scroll;
  gap: 0.25rem;

  .exchange-info {
    text-align: left;
    text-wrap: wrap;
  }
}