.static-contents-container {
    display: flex;
    flex-direction: column;

    .static-contents-header {
        width: 100%;
        text-align: left;
        border-bottom: 1px solid black;
        padding: 1rem;

        h2 {
            margin: 0;
        }
    }

    .static-content-list {
        display: flex;
        flex-direction: row;
        padding: 1.5rem;
        gap: 2rem;
        flex-wrap: wrap;
        justify-content: flex-start;

        .static-content-list-item {
            border: 1px solid black;
            border-radius: 0.3rem;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            width: 30%;
            align-items: center;
            padding: 0.5rem;

            .static-content-list-item-image-conainer {
                width: 100%;
                height: 80%;
            }
        }
    }
}