.visibility-hidden {
  visibility: hidden;
}

.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.55);
}

.ant-select-disabled {
  color: rgba(0, 0, 0, 0.68) !important;
}
