.list-filter-item {
    margin-top: 5px;
    display: grid;
    align-items: center;
    grid-template-columns: 40px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 40px !important;
    height: 75px;
    border: 1px solid black;
    padding: 0 5px;
    color: black;
    cursor: pointer;
    align-items: center;
}
  
.list-filter-item:hover {
    background-color: rgb(248, 225, 225);
    color: #1890ff;
}
  
#trash{
    margin-left: auto;
}