.add-new-box-popup-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    height: 40%;
    background-color: white;
    z-index: 999;
    box-shadow: 0 3px 4px 2px black;
    padding: 1rem;
    gap: 1.5rem;
}

.add-new-box-popup-button-container {
    width: 100%;
    display: flex;
    gap: 0.5rem;
    justify-content: end;
}

.add-new-box-popup-header {
    font-size: 1.2rem;
    font-weight: 600;
    align-self: flex-start;
}