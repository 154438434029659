.list-cargo_company-item {
  margin-top: 5px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 3fr 12fr 4fr 4fr 4fr 2fr;
  height: 75px;
  border: 1px solid black;
  gap: 10px;
  padding: 0 5px;
  cursor: pointer;
}
.list-cargo_company-item:hover {
  background-color: rgb(248, 225, 225);
}

#trash {
  margin-left: auto;
}