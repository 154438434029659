.container {
    padding: 0 10px;
}

.coupon-form {
    width: 100%;
}

.form-top {
    width: 100%;
    display: grid;
    gap: 10px;
    grid-template-columns: 3fr 2fr;
}

.form-left, .form-right {
    padding: 10px;
    border: 1px solid black;
}

.description_box {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    margin: 0 -10px;
    padding: 10px;
}

.header-save {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}

.buttons {
    display: flex;
    gap: 10px;
}

.date-checkbox {
    display: flex;
    align-items: flex-start;
}