.configurations-container {
    padding: 20px;
    
    .ant-card-head-title{
      flex: none !important;
    }
  }
  
  .configurations-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .title {
    text-align: left;
  }
  
  .configurations-list {
    margin-bottom: 20px;
  }
  
  .configurations-form {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 5px;
  }
  
  .configurations-create {
    margin-top: 40px;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 5px;
  }

  .constant-row-container {
    padding: 10px;
    border: 1px solid grey;
    border-radius: 5px;
    background: white !important;
  }
  .constant-row {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    margin-bottom: 16px;
  }

  .configurations-save-button {
    margin-top: 1rem;
  }
  
 

  