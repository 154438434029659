.list-order-item {
  margin-top: 5px;
  display: grid;
  align-items: center;
  grid-template-columns: 25px 3fr 3fr 2fr 3fr 3fr 3fr 3fr 3fr 3fr 30px;
  height: 75px;
  border: 1px solid black;
  gap: 10px;
  padding: 0 5px;
  cursor: pointer;
  text-decoration: none;
  color: black
}
.list-order-item:hover {
  background-color: rgb(248, 225, 225);
}

#trash {
  margin-left: auto;
}
