.bulk-invoice-popup-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    height: 50%;
    background-color: white;
    z-index: 999;
    box-shadow: 0 3px 4px 2px black;
    padding: 1rem;
    gap: 1rem;
    overflow-y: auto;
}

.bulk-invoice-popup-button-container {
    width: 100%;
    display: flex;
    gap: 0.5rem;
    justify-content: end;
}

.bulk-invoice-popup-header {
    font-size: 1.2rem;
    font-weight: 600;
    align-self: flex-start;
}

.inline-three-inputs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px
}

.inline-two-inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px
}