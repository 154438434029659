.list-product-item {
  margin-top: 5px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 3fr 12fr 4fr 4fr 4fr 4fr 2fr 2fr;
  grid-template-areas: " B-check B-image B-product B-status B-type B-tag B-vendor B-price";
  height: 75px;
  border: 1px solid black;
  padding: 0 5px;
  cursor: pointer;
}
.list-product-item:hover{
  background-color: rgb(248, 225, 225);
}

.list-product-item-check {
  grid-area: B-check;
}
.list-product-item-image {
  grid-area: B-image;
}
.list-product-item-product {
  grid-area: B-product;
}
.list-product-item-status {
  grid-area: B-status;
}
.list-product-item-type {
  grid-area: B-type;
}
.list-product-item-tag {
  grid-area: B-tag;
}
.list-product-item-vendor {
  grid-area: B-vendor;
}
.list-product-item-price {
  grid-area: B-price;
}
