.list-application-filter {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 10px;
}

.application-filter-form {
    display: flex;
    flex-direction: row;
}

.list-applications-table-head {
    background-color: rgb(194, 194, 194);
    display: grid;
    grid-template-columns: 25px 1fr 1fr 2fr 2fr 2fr 2fr 30px;
    gap: 10px;
    padding: 10px 0;
  }